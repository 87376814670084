import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Translate from '../../components/Translate';
import Layout from '../../components/layout';
import quickDraw from '../../data/images/quickdraw-community.png';
import i18next from '../../config/i18next';
import { isChinaUser } from '../../config/isChina';
import themeColors from '../../styles/theme';

const styles = {
    device: {
        textAlign: 'center',
        padding: '30px 0 40px 0',
        borderBottom: '1px solid rgba(199,199,199,0.3)',
        '& h2': {
            lineHeight: '1.25',
            fontSize: '32px',
            fontWeight: '400',
            marginBlockEnd: '0',
            marginBlockStart: '0'
        },
        '& img': {
            height: 'auto',
            maxWidth: '100%',
            verticalAlign: 'middle',
            border: '0',
            maxHeight: '150px'
        }
    },
    button: {
        border: '1px solid ' + themeColors.primaryButtonBackground,
        borderRadius: '3px',
        display: 'inline-block',
        textAlign: 'center',
        margin: '30px 0 30px 0',
        cursor: 'pointer',
        '& a': {
            color: themeColors.white,
            display: 'block',
            padding: '10px 25px'
        }
    },
    enabled: {
        backgroundColor: themeColors.primaryButtonBackground
    },
    disabled: {
        backgroundColor: themeColors.primaryButtonBackground,
        opacity: '0.5',
        cursor: 'not-allowed',
        pointerEvents: 'none',
        '& a': {
            cursor: 'not-allowed',
            pointerEvents: 'none'
        }
    },
    textArea: {
        background: '#fff',
        padding: '40px 22% 0 22%',
        '& label > a': {
            color: themeColors.primaryTextLinks,
            outline: 'none'
        },
        '& hr': {
            borderTop: '1px solid rgba(199,199,199,0.3)',
            margin: '30px 0 30px 0'
        },
        '& p': {
            marginBlockStart: '0',
            fontWeight: '300',
            fontSize: '1.2em',
            marginBottom: '35px',
            '& a': {
                color: themeColors.primaryTextLinks
            }
        }
    }
};

class Quickdraw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            title: '',
            updateAndroidLink: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.setState({ active: event.target.checked });
    }

    componentDidMount() {
        this.loadTitleTranslation();
        this.setState({ updateAndroidLink: isChinaUser() });
    }

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('prelogin_pages', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:garmin_quickdraw_community`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    render() {
        const { classes } = this.props;
        const { active, title } = this.state;
        const cursorClass = active
            ? classNames(classes.button, classes.enabled)
            : classNames(classes.button, classes.disabled);
        const getStartedLink = this.state.updateAndroidLink
            ? `https://connect.garmin.cn/en-US/start/quickdraw/`
            : `https://connect.garmin.com/modern/addMarineRole`;

        return (
            <Layout>
                <div className={classes.content}>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content="Garmin Connect is your home for the Quickdraw Community." />
                    </Helmet>
                    <div className={classes.container}>
                        <div className={classes.device}>
                            <img src={quickDraw} alt="" />
                            <h2>{<Translate ns="prelogin_pages" content="garmin_quickdraw_community" />}</h2>
                        </div>
                    </div>
                    <div className={classes.textArea}>
                        <p>{<Translate ns="prelogin_pages" content="garmin_quickdraw_community_content1" />}</p>
                        <p>{<Translate ns="prelogin_pages" content="garmin_quickdraw_community_content2" />}</p>
                        <p>
                            <a href="https://buy.garmin.com/en-US/US/p/543340">
                                {<Translate ns="prelogin_pages" content="learn_more" />}
                            </a>
                        </p>
                        <hr />
                        <div>
                            <label>
                                <input type="checkbox" checked={active} onChange={this.handleClick} />
                                {
                                    <Translate
                                        ns="prelogin_pages"
                                        content="garmin_quickdraw_community_agreement"
                                        params={{
                                            0: <Link to={'/start/quickdraw-legal'} />
                                        }}
                                    />
                                }
                            </label>
                        </div>
                        <div className={cursorClass}>
                            <a href={getStartedLink}>{<Translate ns="prelogin_pages" content="get_started" />}</a>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(Quickdraw);
